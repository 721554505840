import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from '../../../reducers/rootReducer';
import { withAppShell } from '../../hoc/app_shell/withAppShell';
import { useHistory } from 'react-router-dom';
import { connect } from "react-redux";
import { Grid, Typography } from '@material-ui/core';
import MasterSlaveContainer from '../../ui/MasterSlaveContainer';
import ListComponent from '../../ui/ListComponent';
import { campaignFormatListType } from '../../../utils/dmdTypes';
import { fetchFormatByIdandMajorVersion, fetchFormatList } from '../../../actions/formatAction';
import { handleDateSort, handleStringSort } from '../../../utils/listUtils';
import { getCampaignFormatStatusColorPalette } from '../../../assets/theme';
import { CampaignFormatDetails } from './CampaignFormatDetails';
import { fetchClients } from '../../../actions/clientActions';
// our context sets a property of type string
import theme from '../../../assets/theme';
import { getTypeIcon } from '../../ui/PageWrapper'
import { fetchVendors } from '../../../../src/actions/vendorActions';
import { useDispatch } from "react-redux";
import TabTitleComponent from '../../ui/TabTitleComponent';
import { getLastAction, setSearchDataFromSessionStorage, setSortDataFromSessionStorage } from '../../../utils/paginationUtils';
import { ACTION_TYPE } from '../../../utils/dfbConstants';

const UnconnectedCampaignFormatComponent: React.FC<Props> = (props) => {
  const [campaignFormatList, setCampaignFormatList] = React.useState<Array<campaignFormatListType>>([]);
  const [selectedCampaignFormatIdFromList, setSelectedCampaignFormatIdFromList] = React.useState(0);
  const [detailsLoader, setDetailsLoader] = React.useState(false);
  const [searchCriteria, setSearchCriteria] = React.useState('');
  const [formatSortOptions, setSortOptions] = React.useState<Array<any>>([]);
  const [revertListConfig, setRevertListConfig] = React.useState(false);
 
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function fetchList() {
      setDetailsLoader(true);
      let formatsFromAPI = await props.fetchFormatList();
      let formats: any = formatsFromAPI;
      let tempSortOptions = setSortDataFromSessionStorage(setSortOptions);
      let tempSearchText = setSearchDataFromSessionStorage(setSearchCriteria);
      let lastAction = getLastAction();
      if (formats && formats.length > 0) {
        if(lastAction != null && lastAction === ACTION_TYPE.SORT){
          if(tempSearchText.length > 0){
            formats = filterCampaignFormatsByValue(formats, tempSearchText, searchableFields);
          }
          if(tempSortOptions.length > 0){
            formats = await getUpdatedSortOptions(tempSortOptions, formats);
          }
          setCampaignFormatList(formats);
        }
        else if(lastAction != null && lastAction === ACTION_TYPE.SEARCH){
          if(tempSortOptions.length > 0){
            getUpdatedSortOptions(tempSortOptions,formats);
          }
          if(tempSearchText.length > 0){
            formats = filterCampaignFormatsByValue(formats, tempSearchText, searchableFields);
          }
          setCampaignFormatList(formats);
        }
          if(tempSortOptions.length < 1){
            setSortOptions(sortOptions);
          }
          else{
            setSortOptions(tempSortOptions);
          }
          setCampaignFormatList(formats)
          setRevertListConfig(!revertListConfig);
          setSelectedCampaignFormatIdFromList(formats[0].campaignFormatId);
          await props.fetchFormatByIdandMajorVersion(formats[0].campaignFormatId, formats[0].campaignFormatMajorVersion);
          props.fetchClients(`category=client`);
          if (formats[0]?.client?.clientId != null) {
            props.fetchVendors(`category=vendor&getBy=clientId&clientId=${formats[0].client.clientId}`);
          }
      }
      setRevertListConfig(!revertListConfig);
      setDetailsLoader(false);
    }
    fetchList();
  }, [])

  const getUpdatedSortOptions = async (sortOptionArray:any, formatLists:any) =>{

    let tempSortArray:any = sortOptionArray;
    let sortObject:any;
    for(let i=0;i<tempSortArray.length;i++){
          if(tempSortArray[i].sortOrder !== 0){
            sortObject = tempSortArray[i];
          }
     }
     if(sortObject != null){
      formatLists = await handleSort(sortObject, formatLists);
     }
     return formatLists;
  }
  let listRowRenderer = (format: any) => {
    let row = (
      <Grid container>
        {format.formatType != undefined &&
          <Grid item xs={2}>
            {getTypeIcon(format.formatType, 40)}
          </Grid>}
        <Grid item xs={8} style={{ padding: theme.spacing(0, 0, 0, 1) }}>
          <Typography variant='body2' style={{ fontWeight: 600 }}>
            {format.formatName}
          </Typography>
          <Typography variant='caption' style={{ opacity: 0.87 }}>
            {format.formatDescription ? format.formatDescription : ''}
          </Typography>
        </Grid>
        <Grid item container justify='flex-end' xs={2}>
          <Typography variant='overline' align='right' style={{ color: getCampaignFormatStatusColorPalette(format.campaignFormatsStatus.statusCode) }}>
            {format.campaignFormatsStatus.statusDescription.toUpperCase()}
          </Typography>
          <Typography variant='caption' style={{ opacity: 0.87 }}>
            {`V ${format.campaignFormatMajorVersion}.${format.minorVersion}`}
          </Typography>
        </Grid>
      </Grid>
    )
    return row;
  }
  const filterCampaignFormatsByValue = (array: Array<any>, seachValue: string, allowedFields: Array<any>) => {
    return array.filter(o =>
      Object.keys(o).some(k => {
        let found = false
        if (k === 'campaignFormatsStatus' || k === 'formatType') {
          found = Object.keys(o[k]).some(key => {
            return allowedFields.indexOf(key) > -1 && String(o[k][key]).toLowerCase().includes(seachValue.toLowerCase())
          })
        } else if (k === 'minorVersion' || k === 'campaignFormatMajorVersion') {
          found = `V ${String(o['campaignFormatMajorVersion'])}.${o['minorVersion']}`.toLowerCase().includes(seachValue.toLowerCase())
          // return found;
        }
        else {
          return allowedFields.indexOf(k) > -1 && String(o[k]).toLowerCase().includes(seachValue.toLowerCase())
        }
        return found
      }));
  }
  const searchableFields = ['formatName', 'minorVersion', 'campaignFormatMajorVersion', 'formatDescription', 'statusName', 'formatTypeName'];
  const handleSearch = async (searchText: string) => {
    let tempRows: any = filterCampaignFormatsByValue(props.formats ? props.formats : [], searchText, searchableFields);
    setCampaignFormatList(tempRows ? tempRows : []);
     setDetailsLoader(true);
      setSelectedCampaignFormatIdFromList(tempRows[0].campaignFormatId);
      await props.fetchFormatByIdandMajorVersion(tempRows[0].campaignFormatId, tempRows[0].campaignFormatMajorVersion);
      setDetailsLoader(false);
  }
  const sortOptions = [{ sortLabel: 'Created date', sortField: 'createdDate' },
  { sortLabel: 'Format type', sortField: 'formatType.formatTypeAbbreviation' },
  { sortLabel: 'Format name', sortField: 'formatName' }];

  const handleSort = async (sortOptions: any, formatLists?:any) => {
    let array: any = []
    if (sortOptions.sortOrder === 0) {
      setCampaignFormatList(props.formats ? props.formats : [])
    }
    else if (sortOptions.sortField === 'createdDate') {
      if(formatLists != null && formatLists.length > 0){
        array = handleDateSort(formatLists, sortOptions.sortField, sortOptions.sortOrder);
      }else{
        array = handleDateSort(campaignFormatList, sortOptions.sortField, sortOptions.sortOrder);
      }
      setCampaignFormatList(array);
    }
    else {
      if(formatLists != null && formatLists.length > 0){
        array = handleStringSort(formatLists, sortOptions.sortField, sortOptions.sortOrder);
      }else{
        array = handleStringSort(campaignFormatList, sortOptions.sortField, sortOptions.sortOrder);
      }
      setCampaignFormatList(array);
    }
    if (array.length > 0 && formatLists == null) {
      setDetailsLoader(true);
      setSelectedCampaignFormatIdFromList(array[0].campaignFormatId);
      await props.fetchFormatByIdandMajorVersion(array[0].campaignFormatId, array[0].campaignFormatMajorVersion);
      setDetailsLoader(false);
      return array;
    }else{
      return array;
    }
  }
  const handleCampaignFormatClick = async (format: any) => {
    if (!(detailsLoader || props.fetchingFormatInProgress)) {
      setDetailsLoader(true);
      setSelectedCampaignFormatIdFromList(format.campaignFormatId);
      await props.fetchFormatByIdandMajorVersion(format.campaignFormatId, format.campaignFormatMajorVersion);
      if (format.client != null && format.client.clientId != null) {
        props.fetchVendors(`category=vendor&getBy=clientId&clientId=${format.client.clientId}`);
      }
      setDetailsLoader(false);
    }
  }
  return (<div style={{ height: '100%', width: '100%' }}>
    <TabTitleComponent tabTitle={props.messages.Formats}></TabTitleComponent>
    <MasterSlaveContainer
      listComponent={
        <ListComponent
          data={campaignFormatList}
          rowsRenderer={listRowRenderer}
          searchLabel='Search format'
          handleSearch={handleSearch}
          sortOptions={formatSortOptions}
          handleSort={handleSort}
          handleListItemClick={handleCampaignFormatClick}
          pagination={true}
          listLoader={props.formatListFetchingInProgress}
          selectedItemFieldName={'campaignFormatId'}
          selectedItemFieldValue={selectedCampaignFormatIdFromList}
          refreshList={false}
          searchCriteria={searchCriteria}
          setSearchCriteria={setSearchCriteria}
          history={history}
          revertListConfig={revertListConfig}
          setRevertListConfig={setRevertListConfig} 
        />
      }
      detailsComponent={
        <CampaignFormatDetails
          campaignFormat={props.selectedFormat}
          pageLoader={detailsLoader || props.fetchingFormatInProgress} />
      }
    />
  </div>)
}

const mapStateToProps = (state: RootState) => (
  {
    user: state.user.user,
    messages: state.messageBundle.messages,
    formats: state.format.formatList,
    formatListFetchingInProgress: state.format.formatListFetchingInProgress,
    selectedFormat: state.format.selectedFormat,
    fetchingFormatInProgress: state.format.fetchingFormatInProgress,
    vendor: state.vendor,
    vendorsFetchingInProgress: state.vendor.vendorsFetchingInProgress,
    clientsFetchingInProgress: state.client.clientsFetchingInProgress
  })
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    fetchFormatList,
    fetchFormatByIdandMajorVersion,
    fetchVendors,
    fetchClients
  }, dispatch)
};
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export const CampaignFormatComponent = withAppShell(connect(mapStateToProps, mapDispatchToProps)(UnconnectedCampaignFormatComponent), { enableNav: true })